import React, { useEffect, useContext } from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import { AppContext } from "../context/AppContext"

import Section from "../components/section/Section"

const Aviso = () => {
  const { setLogoColor } = useContext(AppContext)
  useEffect(() => {
    setLogoColor(false)
  }, [])
  return (
    <Layout title="Aviso de privacidad" className="bg-blue2">
      <Section>
        <div className="flex justify-center"></div>
        <div className="mt-20">
          <div className="text-left col-span-3">
            <p className="text-xl">AVISO DE PRIVACIDAD</p>
            <div className="text-base sm:px-16 py-8">
              <p className="mb-4">
                E-Installations SA de CV, mejor conocido como ikaro, con
                domicilio en calle Vicente Guerrero No.35, colonia San Andrés
                Ahuayucan, Ciudad de México, alcaldia Xochimilco, C.P. 16810, en
                la entidad de Ciudad de México, país México, es el responsable
                del uso y protección de sus datos personales, y al respecto le
                informamos lo siguiente:
              </p>
              <p className="font-bold mb-4">
                ¿Para qué fines utilizaremos sus datos personales?
              </p>
              <p className="mb-4">
                Los datos personales que recabamos de usted, los utilizaremos
                para las siguientes finalidades que son necesarias para el
                servicio que solicita:
              </p>
              <ul className="text-2xl font-light list-disc mb-4 pl-4">
                <li>
                  Cumplir obligaciones de una relación jurídica mediante un
                  contrato.
                </li>
                <li>
                  De manera adicional, utilizaremos su información personal para
                  las siguientes finalidades secundarias que no son necesarias
                  para el servicio solicitado, pero que nos permiten y facilitan
                  brindarle una mejor atención:{" "}
                </li>
                <li className="mb-4">Fines de identificación.</li>
                <li>Realizar el análisis de crédito.</li>
                <li>
                  Ceder los derechos sobre la compraventa de equipos y
                  productos.
                </li>
                <li>Mercadotecnia o publicitaria.</li>
                <li>Prospección comercial.</li>
              </ul>
              <p className="mb-4">
                En caso de que no desee que sus datos personales se utilicen
                para estos fines secundarios, indíquelo a continuación: No
                consiento que mis datos personales se utilicen para los
                siguientes fines:
              </p>
              <p className="mb-4">
                [ ] Fines de identificación.
                <br /> [ ] Realizar el análisis de crédito.
                <br /> [ ] Ceder los derechos sobre la compraventa de equipos y
                productos. <br />[ ] Mercadotecnia o publicitaria.
                <br /> [ ] Prospección comercial.
              </p>
              <p className="mb-4">
                La negativa para el uso de sus datos personales para estas
                finalidades no podrá ser un motivo para que le neguemos los
                servicios y productos que solicita o contrata con nosotros.
              </p>
              <p className="font-bold mb-4">
                ¿Qué datos personales utilizaremos para estos fines?
              </p>
              <p className="mb-4">
                Para llevar a cabo las finalidades descritas en el presente
                aviso de privacidad, utilizaremos los siguientes datos
                personales:
              </p>
              <p className="mb-4">Datos de identificación.</p>
              <p className="mb-4">
                o Nombre completo. o Dirección actual de residencia. o RFC. o
                Dirección para la ejecución del servicio y suministro de
                equipos.
              </p>
              <p className="mb-4">Datos de contacto.</p>
              <p className="mb-4">o Número telefónico. o Correo eléctronico.</p>
              <p className="mb-4">Datos patrimoniales y/o financieros.</p>
              <p className="mb-4">
                ¿Cómo puede acceder, rectificar o cancelar sus datos personales,
                u oponerse a su uso?
              </p>
              <p className="mb-4">
                Usted tiene derecho a conocer qué datos personales tenemos de
                usted, para qué los utilizamos y las condiciones del uso que les
                damos (Acceso). Asimismo, es su derecho solicitar la corrección
                de su información personal en caso de que esté desactualizada,
                sea inexacta o incompleta (Rectificación); que la eliminemos de
                nuestros registros o bases de datos cuando considere que la
                misma no está siendo utilizada adecuadamente (Cancelación); así
                como oponerse al uso de sus datos personales para fines
                específicos (Oposición). Estos derechos se conocen como derechos
                ARCO.
              </p>
              <p className="mb-4">
                Para el ejercicio de cualquiera de los derechos ARCO, usted
                deberá presentar la solicitud respectiva a través del siguiente
                medio:
              </p>
              <p className="mb-4">
                Respecto a los datos personales que proporcione a ikaro, usted
                se encuentra totalmente facultado para ejercer los derechos de
                acceso, rectificación, cancelación y/u oposición (Derechos ARCO)
                Para conocer el procedimiento y requisitos para el ejercicio de
                los derechos ARCO, ponemos a su disposición el siguiente medio:
              </p>
              <p className="mb-4">
                Mediante el envío de un correo electrónico a la siguiente
                dirección: hola@ikaro.mx Los datos de contacto de la persona o
                departamento de datos personales, que está a cargo de dar
                trámite a las solicitudes de derechos ARCO, son los siguientes:
              </p>
              <p className="mb-4">
                a) Nombre de la persona o departamento de datos personales: La
                responsabilidad del manejo de sus datos personales estará a
                cargo y bajo la responsabilidad del área de Operaciones,
                Crédito, Jurídico, y demás áreas responsables de ikaro.
              </p>
              <p className="mb-4">
                b) Domicilio: calle Vicente Guerrero 35, colonia San Andrés
                Ahuayucan, Ciudad de México, alcaldia Xochimilco, C.P. 16810, en
                la entidad de Ciudad de México, país México.
              </p>
              <p className="mb-4">c) Correo electrónico: hola@ikaro.mx</p>
              <p className="mb-4">
                Usted puede revocar su consentimiento para el uso de sus datos
                personales{" "}
              </p>
              <p className="mb-4">
                Usted puede revocar el consentimiento que, en su caso, nos haya
                otorgado para el tratamiento de sus datos personales. Sin
                embargo, es importante que tenga en cuenta que no en todos los
                casos podremos atender su solicitud o concluir el uso de forma
                inmediata, ya que es posible que por alguna obligación legal
                requiramos seguir tratando sus datos personales. Asimismo, usted
                deberá considerar que para ciertos fines, la revocación de su
                consentimiento implicará que no le podamos seguir prestando el
                servicio que nos solicitó, o la conclusión de su relación con
                nosotros.
              </p>
              <p className="mb-4">
                Para revocar su consentimiento deberá presentar su solicitud a
                través del siguiente medio:
              </p>
              <p className="mb-4">
                Usted puede revocar el consentimiento que, en su caso, nos haya
                otorgado para el tratamiento de sus datos personales. Sin
                embargo, es importante que tenga en cuenta que no en todos los
                casos podremos atender su solicitud o concluir el uso de forma
                inmediata, ya que es posible que por alguna obligación legal
                requiramos seguir tratando sus datos personales. Asimismo, usted
                deberá considerar que para ciertos fines, la revocación de su
                consentimiento implicará que no le podamos seguir prestando el
                servicio que nos solicitó, o la conclusión de su relación con
                nosotros.
              </p>
              <p className="mb-4">
                Para conocer el procedimiento y requisitos para la revocación
                del consentimiento, ponemos a su disposición el siguiente medio:
              </p>
              <p className="mb-4">
                Mediante el envío de un correo electrónico a la siguiente
                dirección: hola@ikaro.mx
              </p>
              <p className="mb-4">
                ¿Cómo puede limitar el uso o divulgación de su información
                personal?
              </p>
              <p className="mb-4">
                Con objeto de que usted pueda limitar el uso y divulgación de su
                información personal, le ofrecemos los siguientes medios:
              </p>
              <p className="mb-4">
                Con objeto de que usted pueda limitar el uso y divulgación de su
                información personal, le ofrecemos los siguientes medios: Su
                inscripción en el Registro Público para Evitar Publicidad, que
                está a cargo de la Procuraduría Federal del Consumidor, con la
                finalidad de que sus datos personales no sean utilizados para
                recibir publicidad o promociones de empresas de bienes o
                servicios. Para mayor información sobre este registro, usted
                puede consultar el portal de Internet de la PROFECO, o bien
                ponerse en contacto directo con ésta.
              </p>
              <p className="mb-4">
                Asimismo, usted se podrá inscribir a los siguientes registros,
                en caso de que no desee obtener publicidad de nuestra parte:
              </p>
              <p className="mb-4">
                Registro Público para Evitar Publicidad, para mayor información
                consulte el portal de internet de la PROFECO
              </p>
              <p className="mb-4">
                ¿Cómo puede conocer los cambios en este aviso de privacidad?
              </p>
              <p className="mb-4">
                El presente aviso de privacidad puede sufrir modificaciones,
                cambios o actualizaciones derivadas de nuevos requerimientos
                legales; de nuestras propias necesidades por los productos o
                servicios que ofrecemos; de nuestras prácticas de privacidad; de
                cambios en nuestro modelo de negocio, o por otras causas. Nos
                comprometemos a mantenerlo informado sobre los cambios que pueda
                sufrir el presente aviso de privacidad, a través de: El presente
                aviso de privacidad puede sufrir modificaciones, cambios o
                actualizaciones derivadas de nuevos requerimientos legales.
              </p>
              <p className="mb-4">
                El procedimiento a través del cual se llevarán a cabo las
                notificaciones sobre cambios o actualizaciones al presente aviso
                de privacidad es el siguiente:
              </p>
              <p className="mb-4">
                Cualquier modificación o transferencia será debidamente
                notificada mediante el aviso personal, envío de correo
                electrónico actualización en la pagina web www.ikaro.mx.
              </p>
              <p className="mb-4">ATENTAMENTE</p>
              <p className="mb-4">
                E-Installations SA de CV <br />
                Última actualización: 02/07/2021
              </p>
            </div>
          </div>
        </div>
      </Section>
      <div className=" mt-auto bg-white pt-4 pb-6 w-full text-blue flex justify-center">
        <div className="container text-white">
          <div className="sm:grid sm:grid-cols-3">
            <p className="text-sm sm:text-base text-blue font-medium mb-2 sm:mb-0">
              © Ikaro 2022. Todos los derechos reservados.
            </p>
            <p className=" font-medium text-sm sm:text-base sm:text-center mb-2 sm:mb-0">
              <span
                className="cursor-pointer text-blue"
                onClick={() => navigate("/aviso")}
              >
                Aviso de privacidad
              </span>
            </p>
            <p className="text-blue font-medium  text-sm sm:text-base sm:text-right">
              Diseño y desarrollo web por{" "}
              <a
                href="https://www.trazovivo.com"
                target="_blank"
                className="text-blue2 font-display max-w-sm leading-tight"
                rel="noreferrer"
              >
                <span className="text-blue link link-underline link-underline-black ">
                  Trazo Vivo
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Aviso
